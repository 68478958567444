import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import EmailInput from "src/components/marketing/email-input"
import MindanceAppSection from "src/components/marketing/mindance-app-section"
import TeamSection from "src/components/marketing/team-section"
import GetStartedSection from "src/components/marketing/get-started-section"
import SignatureSection from "src/components/marketing/signature-section"
import DemoVideoSection from "src/components/marketing/demo-video-section"
import WorkshopsSection from "src/components/marketing/workshops-section"
import TitleStrip from "src/components/marketing/title-strip"
import SEO from "src/components/seo"
import "src/styles/pages/marketing-page.scss"

const REGISTRATION_CODE = process.env.GATSBY_LVB_REGISTRATION_CODE

const WORKSHOPS = [
  {
    date: "23.10.2020",
    time: "9.00-10.30 Uhr",
    name: "Besser Schlafen durch Meditation und Entspannung",
    location: "Online Seminar",
  },
  {
    date: "06.11.2020",
    time: "9.00-11.00 Uhr",
    name: "Einführung in Achtsamkeit und Entspannung mit Mindance",
    location: "Präsenzworkshop LVB Zentrale",
  },
  {
    date: "20.11.2020",
    time: "9.00-11.00 Uhr",
    name: "Methoden zur konstruktiven Stressbewältigung",
    location: "Präsenzworkshop LVB Zentrale",
  },
  {},
]
export default function LVBLandingPage() {
  return (
    <Layout className="marketing-page-root">
      <TitleStrip
        logos={[
          "/assets/img/marketing/logo_lvb.png",
          "/assets/img/marketing/Group 1960@2x.png",
          "/assets/img/marketing/mindance_anmeldung.png",
        ]}
      />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title:
            "Endlich weniger Stress, besserer Schlaf und ein gesteigertes Wohlbefinden!",
          description:
            "Mit der Gesundheitsapp Mindance, spannenden Workshops & Online-Seminaren und psychologischer Beratung.",
        }}
      >
        <EmailInput
          short
          placeholder="E-Mail geschäftlich"
          registrationCode={REGISTRATION_CODE}
        />
      </Jumbotron>
      <SEO
        title="LVB"
        description="Mit der Gesundheitsapp Mindance, spannenden Workshops & Online-Seminaren und psychologischer Beratung."
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
      />
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h2>Stress reduzieren und das Wohlbefinden steigern</h2>
          <p>
            Der Arbeitsalltag — egal, ob im Büro, im Fahrdienst oder in der
            Werkstatt — kann einen schnell einmal an die eigenen Grenzen
            bringen. Stress, Anspannung oder Frustration sind nicht selten die
            Folge. Das hat auf Dauer negative Folgen: Unser Wohlbefinden ist
            vermindert, wir fühlen uns ausgebrannt, können uns nicht mehr
            konzentrieren und auch unsere Beziehungen leiden.
          </p>
          <br />
          <h2>Mindance kostenlos für die Leipziger Verkehrsbetriebe!</h2>
          <p>
            Mindance ist ein Leipziger Unternehmen, das sich der Mission
            verschrieben hat, zu einer Welt beizutragen, in der mentale
            Gesundheit denselben Stellenwert hat wie körperliche Gesundheit und
            in der arbeitsbedingte psychische Erkrankungen der Vergangenheit
            angehören. Alle LVB Mitarbeiter*innen erhalten kostenlosen Zugriff
            auf das Angebot von Mindance.
          </p>
          <br />
          <p className="green-text text-l">
            <b>
              Das ist die Chance für Dich, Deine psychische Gesundheit
              nachhaltig zu fördern!
            </b>
          </p>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/marketing/mindfrinds_achtsamkeit_reflection.jpg"
          />
        </Grid>
      </Grid>
      <MindanceAppSection
        titles={[
          "Checke Deine mentale Gesundheit und erhalte Deinen persönlichen Trainingsplan",
          "Nutze die große Auswahl an Übungen und Kursen",
          "Verfolge alle Deine Fortschritte auf einen Blick",
        ]}
        descriptions={[
          "Starte mit einem Fragebogen, der Dir zeigt, in welchen Bereichen Du Deine psychische Gesundheit verbessern kannst. Du erhältst basierend auf Deinem persönlichen Ergebnis einen Plan mit verschiedenen Übungen. Anschließend kannst Du selbst bestimmen, wie viel Zeit Du pro Woche in das Training investieren möchtest. Sei es einmal wöchentlich für fünf Minuten oder aber täglich jeden Morgen für 20 Minuten - Du hast die Wahl.",
          "In der App findest Du ein 8-wöchiges Grundlagentraining, das Dich dabei unterstützen kann, Deine eigenen Gedanken und Gefühle besser zu verstehen. Außerdem kannst Du nach Belieben Übungen zu verschiedenen Themenbereichen, wie Stress reduzieren, Beziehungen stärken, Besser schlafen oder Konzentration steigern wählen und zwischen 5- und 15- Minuten die Übungslänge einstellen, die für Dich in dem Moment ideal ist.",
          "Unter Deinem Profil findest Du die Timeline Deiner absolvierten Übungen, Statistiken über Dein Training mit Mindance und die Ergebnisse des Fragebogens. Unser Tipp: Regelmäßiges Üben macht den Meister, damit ist Regelmäßigkeit auch erst einmal wichtiger als langes Üben.",
        ]}
      >
        <DemoVideoSection
          title="Du bist gespannt, was Dich in der Mindance App erwartet? Dann kannst Du in diesem Video direkt Deine erste Übung ausprobieren."
          description="Setze Dich dazu an einen Ort, an dem du 5 Minuten ungestört bist und folge den Anweisungen des Sprechers, der Dich durch den Body Scan leiten wird."
        />
      </MindanceAppSection>
      <TeamSection>
        <h1>Psychologische Beratung über die Chatfunktion oder Telefonate</h1>
        <p>
          <b>
            Du hast Fragen zum Etablieren einer Routine, möchtest konkrete Ziele
            des Trainings gemeinsam ausloten und das Mindance Programm genauer
            kennen lernen? Oder möchtest Du über persönliche Herausforderungen
            und belastende Gefühle und Gedanken mit einer außenstehenden Person
            sprechen?
          </b>
          <br />
          Dann schreibe unseren psychologischen Berater*innen entweder über den
          Chat in der App oder vereinbare dort ein Telefonat mit ihnen.
        </p>
        <p>
          <br />
          Keine Sorge, unsere beratenden Psychologinnen und Psychologen
          unterliegen der gesetzlichen Schweigepflicht - somit werden alle
          Daten, die uns in diesem Rahmen erreichen, streng vertraulich
          behandelt und unter keinen Umständen an Krankenkassen, Deinen
          Arbeitgeber oder Drittanbieter weitergeleitet.
        </p>
      </TeamSection>
      <WorkshopsSection workshops={WORKSHOPS} />
      <GetStartedSection
        title="Lege direkt los"
        subtitle="Um die App zu installieren, folge einfach diesen drei Schritten:"
        steps={[
          "Lade Dir die Mindance App im App Store / PlayStore unter dem Stichwort “Mindance” herunter.",
          "Gib Deine E-Mail ein, um einen Aktivierungscode zu erhalten.",
          "Erstelle Dir unter Verwendung Deines Aktivierungscodes Dein persönliches, anonymes Profil in der App.",
        ]}
        emailComponent={
          <EmailInput
            placeholder="E-Mail Adresse"
            registrationCode={REGISTRATION_CODE}
          />
        }
      />
      <SignatureSection />
    </Layout>
  )
}
